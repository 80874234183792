.App {
  text-align: center;
  font-family: 'Open Sans';

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* css related to recharts bar graph used in BA Admin */

.chart-container {
  width: 100%; /* The chart container will occupy 100% of its parent's width */
  max-width: 600px; /* Optionally set a maximum width for the chart */
  margin: 0 auto; /* Center the chart within its parent container (optional) */
}

.recharts-bar-rectangle {
  border-radius: 50px; /* Adjust the radius value to control the roundness of the edges */
}

/* .recharts-default-legend {
  width: 100%;
  display: flex;
  justify-content: space-around;
} */


.recharts-legend-icon {
  clip-path: path(
    'M 8 0 h 16 a 8  0 0 1 8 8 v 16 a 8 8 0 0 1 -8 8 h -16 a 8 8 0 0 1 -10  v -16 a 8 8 0 0 1 10 8 -8 Z'
  );
}

.recharts-legend-item .recharts-surface {
  width: 34.22px;
  height: 21px;
}

.recharts-legend-item .recharts-legend-item-text {
  color: #636363 !important;
  font-family: 'Montserrat-Medium', Helvetica;
  letter-spacing: 0;
  line-height: normal;
}
/*  */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes alertAnimation {
  0% { 
    opacity: 0.2;
    top:60px;
  }
  40% { 
    opacity: 0.4;
    top:70px;
  }
  90% {
    opacity: 0.6;
    top:83px;
  }
  100% {
    opacity: 1;
    top:80px;
  }
}


@keyframes alertTimeAnimation {
  0% { 
    width:100%;
  }
  100% {
    width:0%;
  }
}