.ellipsis-container {

    /* box-sizing: border-box; */
    max-width: 250px;
    position: absolute;
    bottom: 25px;
    left: 5px;
    border-radius: 8px;
    background: white;
    /* background: #e2e2fe; */
    z-index: 4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    padding: 10px;

    color: rgba(0, 0, 0, 0.81);
    font-size: 13px;
    font-weight:500;

    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    /* white-space: nowrap; */

    width: 250px;

    max-height: 160px;
    /* Set the maximum height */
    overflow: hidden;
    /* Hide content that exceeds max-height */
    /* position: relative; Required for absolute positioning of the ellipsis */
}

.ellipsis-content {
    display: -webkit-box;
    /* Flexbox layout for webkit browsers */
    -webkit-box-orient: vertical;
    /* Vertically orient the box */
    -webkit-line-clamp: 3;
    /* Number of lines to show before truncating */
    overflow: hidden;
    /* Hide overflow content */
    text-overflow: ellipsis;
    /* Display ellipsis for overflowed text */
}

.ellipsis-container::after {
    /* content: '...'; Add ellipsis */
    position: absolute;
    bottom: 0;
    right: 0;
    background: linear-gradient(to bottom, transparent, white);
    /* Gradual fade effect */
    padding: 0 10px;
    /* Space for ellipsis */
}