/* Define CSS for the optioncss class */
.optioncss {
    /* Add your general styling for options here */
    /* For example: */
    font-weight: normal;
    background: white;
}

.Select_drop:hover {


    background-color: #f4f4f4 !important;


}

.optioncss :hover {
    background-color: #f4f4f4 !important;

}

/* Define CSS for the selected class */
.selected {
    /* Add your styling for selected options here */
    /* For example: */
    font-weight: bold;
    background-color: #f4f4f4 !important;
}



.custom-select .react-select__menu {
    width: 185px;
    z-index: 1000;
    text-align: start;
    /* Adjust the z-index value as needed */
}

.css-1nmdiq5-menu {

    z-index: 1000 !important;
    /* Adjust the z-index value as needed */
}

.react-select {
    width: 185px;
}

.css-b62m3t-container {
    min-width: 200px;
    text-align: left;
}

.css-13cymwt-control {
    min-height: 41px !important;
    border-radius: 10px !important;
    border-color:  rgb(99, 99, 99);
}