.fullscreen-menu {
  position: fixed;
  top: 90px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ebebeb;
  color: #151515;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  padding-left: 24px;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 999;
  @media (min-width: 375px) {
    opacity: 1;
    visibility: visible;
  }
}

.fullscreen-menu button {
  text-decoration: none;
  font-size: 18px;
  text-align: left;
  margin-top: 40px;
  transition: color 0.3s;
  border: none;
  background: none;
  font-family: Inter;
}

.fullscreen-menu a:hover {
  color: #ff6347;
}
