.demo-request-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  margin-top: 0px;
  font-family: Arial, sans-serif;
  background: linear-gradient(101deg, #5937c8 0%, #391c99 100%);
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 20px 20px 30px 20px;
  }
}

.info-panel {
  flex: 1;
  max-width: 400px;
  padding: 30px;
  border-radius: 8px;
  @media only screen and (max-width: 768px) {
    padding: unset;
  }
}

.info-panel h1 {
  color: #fafafa;
  font-family: Inter;
  font-size: 54px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 32px;
  }
}

.info-panel ul {
  margin-top: 80px;
  margin-bottom: 20px;
  padding-left: 0px;
  list-style: none;
  @media only screen and (max-width: 768px) {
    margin-top: 20px;
  }
}

.info-panel ul li {
  color: #fafafa;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin-bottom: 16px;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.form-panel {
  flex: 1;
  background-color: #fff;
  max-width: 50%;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 768px) {
    max-width: unset;
    padding: 25px 48px 24px 25px;
  }
}

.form-panel label {
  color: #151515;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  display: block;
  text-align: left;
  align-items: center;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.form-panel input,
.form-panel textarea,
.form-panel button,
.form-panel option {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  @media only screen and (max-width: 768px) {
    max-width: unset;
  }
}
.form-panel select {
  width: 103%;
  margin-top: 5px;
  margin-bottom: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  appearance: none;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23000000" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5H7z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 15px;
  @media only screen and (max-width: 768px) {
    width: 106%;
  }
}
.form-panel button {
  background: linear-gradient(101deg, #5937c8 0%, #391c99 100%);
  color: #fff;
  border: none;
  padding: 10px;
  height: 44px;
  font-size: 16px;
  cursor: pointer;
  width: 102%;
  @media only screen and (max-width: 768px) {
    width: 107%;
  }
}
